
require('./bootstrap');


import 'jquery-ui/ui/widgets/datepicker.js';
import 'jquery-ui/ui/widgets/sortable.js';
import 'jquery-ui/ui/widgets/autocomplete.js';
import 'bs-custom-file-input/dist/bs-custom-file-input.js';
import 'dropzone/dist/dropzone.js';
//import 'multiselect/js/jquery.multi-select.js'; 

// require('tinymce');
// require('tinymce/themes/mobile');
// require('tinymce/themes/silver');
// require('tinymce/plugins/paste');
// require('tinymce/plugins/advlist');
// require('tinymce/plugins/autolink');
// require('tinymce/plugins/lists');
// require('tinymce/plugins/link');
// require('tinymce/plugins/image');
// require('tinymce/plugins/charmap');
// require('tinymce/plugins/print');
// require('tinymce/plugins/preview');
// require('tinymce/plugins/anchor');
// require('tinymce/plugins/textcolor');
// require('tinymce/plugins/searchreplace');
// require('tinymce/plugins/visualblocks');
// require('tinymce/plugins/code');
// require('tinymce/plugins/fullscreen');
// require('tinymce/plugins/insertdatetime');
// require('tinymce/plugins/media');
// require('tinymce/plugins/table');
// require('tinymce/plugins/contextmenu');
// require('tinymce/plugins/code');
// require('tinymce/plugins/help');
// require('tinymce/plugins/wordcount');
// require('tinymce/icons/default');

window.bsCustomFileInput = require('bs-custom-file-input');
window.multiSelect = require('multiselect');
window.dropzone = require('dropzone');

